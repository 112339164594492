/* eslint-disable react-hooks/exhaustive-deps */
import { useState, FC } from 'react';
import { TypingResponse as TR } from 'types';
import { useExperimentContext, useResponsePhaseContext, useTrialRunnerContext } from 'context';
import { ProgressBar } from 'components';
import { defaults, getImageFilePath } from 'utility';

interface Props {
	currentResponse: TR;
	trialNumber?: number;
	correctAnswer?: string;
}

/**
 *
 */
export const TypingResponse: FC<Props> = ({ currentResponse }) => {
	const { experimentName } = useExperimentContext();
	const { presentationElements } = useTrialRunnerContext();
	const { addUserResponse, endResponseForcibly } = useResponsePhaseContext();
	const [currentText, setCurrentText] = useState('');

	const timeToRespond = currentResponse.timeToRespond ?? defaults.trialRunner.response.timeToRespond;
	const progressBarStatus = currentResponse.progressBarStatus as boolean;
	const { x } = currentResponse;
	const { y } = currentResponse;
	const { width } = currentResponse;
	const height = currentResponse.height ? currentResponse.height : 1;

	// Alerts user if they enter invalid input
	const checkValidInput = (response: string) => {
		const onlyLetters = /^[A-Z a-z ' .]+$/;
		const value = response;
		if (value !== undefined && value !== null && value !== '') {
			if (value.match(onlyLetters) && value.length > 2) {
				addUserResponse(value);
			} else if (!value.match(onlyLetters)) {
				alert('Type only alphabetic characters');
			} else if (value.length < 3) {
				alert('Type the entire word');
			}
		} else {
			alert('Response required');
		}
	};

	return (
		<>
			<ProgressBar doWhenFinished={endResponseForcibly} length={timeToRespond} status={progressBarStatus} />
			{currentResponse.message && (
				<h3
					style={{
						textAlign: 'center',
						top: y ? `${(y * window.innerHeight) / 100 - (50 + 25 * height)}px` : 'auto',
						left: x ? `${(x * window.innerWidth) / 100 - 100}px` : 'auto',
						width: 'auto',
						position: x ? 'absolute' : 'relative'
					}}
				>
					{currentResponse.message}
				</h3>
			)}
			{presentationElements.filter((pe) => pe.showDuringResponse).map((pe) => pe.element)}
			{currentResponse.imageSource && (
				<div style={{ textAlign: 'center' }}>
					<img src={getImageFilePath(experimentName!, currentResponse.imageSource)} alt='' width='150px' />
				</div>
			)}
			<form
				action=''
				onSubmit={(e) => {
					e.preventDefault();
					checkValidInput(currentText);
				}}
			>
				<input
					autoFocus
					type='text'
					autoComplete='off'
					value={currentText}
					onChange={(e) => setCurrentText(e.target.value)}
					style={{
						textAlign: 'left',
						paddingTop: '75px',    /* MP: Increased from 30 to 75 to separate message from response */
						top: y ? `${(y * window.innerHeight) / 100}px` : 'auto',
						left: x ? `${(x * window.innerWidth) / 100 - 100}px` : 'auto',
						width: width ? `${width}px` : '500px',
/*	    			    height: height ? `${height}px` : '50px',  MP: otherwise text is hidden */
						position: x ? 'absolute' : 'relative',
/*						border: '1px solid black'      MP: green line is cleaner than a box */
					}}
				/>
			</form>
		</>
	);
};
